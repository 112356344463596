import React, { createContext, useContext, useReducer } from 'react'

let initialState
if (process.env.NODE_ENV === 'development') {
  initialState = {
    modal: false,
    videoPopup: false,
    imagePopup: false,
    age: true,
    activeScreen: 3,
    sticky: true,
    section: null,
    ytConsent: null,
    galleryImage: null,
  }
} else {
  //production / build only
  initialState = {
    modal: false,
    videoPopup: false,
    imagePopup: false,
    age: null,
    sticky: true,
    activeScreen: 1,
    section: null,
    ytConsent: null,
    galleryImage: null,
  }
}

const stateReducer = (state, payload) => {
  switch (payload.action) {
    case 'SET_MODAL':
      return { ...state, modal: payload.value }
    case 'SET_VIDEO_POPUP':
      return { ...state, videoPopup: payload.value }
    case 'SET_IMAGE_POPUP':
      return { ...state, imagePopup: payload.value }
    case 'SET_STICKY':
      return { ...state, sticky: payload.value }
    case 'SET_AGE':
      return { ...state, age: payload.value }
    case 'SET_ACTIVE_SCREEN':
      return { ...state, activeScreen: payload.value }
    case 'SET_ACTIVE_SECTION':
      return { ...state, section: payload.value }
    case 'SET_YT_CONSENT':
      return { ...state, ytConsent: payload.value }
    case 'SET_GALLERY_IMAGE':
      return { ...state, galleryImage: payload.value }
    default:
      return state
  }
}

export const StateContext = createContext({})

export const StateContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(stateReducer, initialState)

  const StateContextStore = {
    modal: state.modal,
    videoPopup: state.videoPopup,
    imagePopup: state.imagePopup,
    sticky: state.sticky,
    age: state.age,
    activeScreen: state.activeScreen,
    section: state.section,
    firstScreenActive: state.age === null,
    ageFalseActive: state.activeScreen === null,
    secondScreenActive: state.activeScreen === 2,
    thirdScreenActive: state.activeScreen === 3, //landing page
    modalHandle: (boo) => dispatch({ action: 'SET_MODAL', value: boo }),
    videoPopupHandle: (boo) =>
      dispatch({ action: 'SET_VIDEO_POPUP', value: boo }),
    imagePopupHandle: (boo) =>
      dispatch({ action: 'SET_IMAGE_POPUP', value: boo }),
    stickyHandle: (boo) => dispatch({ action: 'SET_STICKY', value: boo }),
    setAge: (value) => dispatch({ action: 'SET_AGE', value }),
    setActiveScreen: (value) =>
      dispatch({ action: 'SET_ACTIVE_SCREEN', value }),
    setActiveSection: (value) =>
      dispatch({ action: 'SET_ACTIVE_SECTION', value }),
    ytConsent: state.ytConsent,
    setYtConsent: (value) => dispatch({ action: 'SET_YT_CONSENT', value }),
    galleryImage: state.galleryImage,
    setGalleryImage: (value) => dispatch({ action: 'SET_GALLERY_IMAGE', value }),
    dispatch,
  }

  return (
    <StateContext.Provider value={StateContextStore}>
      {children}
    </StateContext.Provider>
  )
}

export const useStateContext = () => useContext(StateContext)
