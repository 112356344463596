module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8d9296481cc34aca63cf1ddb05e42c19"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["de","en"],"defaultLanguage":"de","redirect":false,"i18nextOptions":{"keySeparator":false,"nsSeparator":false,"defaultLanguage":"de","fallbackLng":"de"},"pages":[{"matchPath":"/:lang?/legal-notice","getLanguageFromPath":true,"languages":["en"]},{"matchPath":"/:lang?/dataprotectionterms","getLanguageFromPath":true,"languages":["en"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
