import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { useLocation } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'

const SEO = ({ article }) => {
  const { pathname } = useLocation()
  const lang = pathname.includes('/en/') ? 'en' : 'de'
  const { site } = useStaticQuery(query)
  const { title, siteUrl, description, image } = site.siteMetadata
  const seo = {
    title: lang === 'de' ? title : 'ERDINGER Brauhaus - for the love of beer',
    description: lang === 'de' ? description : 'Werner Brombach and his brewmasters have always brewed their beers using only carefully selected ingredients, supreme skill and a burning passion – for the love of beer.',
    image: `${siteUrl}${lang === 'de' ? image : '/og-image-en.png'}`,
    url: `${siteUrl}${pathname}`,
    matchingUrl: false,
    matchingLang: false,
  }

  const normalizedPath = pathname; // pathname.replace(/\/$/, ''); // remove last slash from pathname
  const matchingEnUrls = {
    '/' : '/en/',
    '/impressum/' : '/en/legal-notice/',
    '/datenschutz/' : '/en/dataprotectionterms/',
  };
  const matchingDeUrls = Object.fromEntries(Object.entries(matchingEnUrls).map(a => a.reverse()));
  const fixHomepage = (path) => (path == '/' || path == '//') ? '' : path;

  if (matchingEnUrls[normalizedPath] !== undefined) {
    seo.matchingUrl = siteUrl + fixHomepage(matchingEnUrls[normalizedPath]);
    seo.matchingLang = 'en';
  } else if (matchingDeUrls[normalizedPath] !== undefined) {
    seo.matchingUrl = siteUrl + fixHomepage(matchingDeUrls[normalizedPath]);
    seo.matchingLang = 'de';
  }

  return (
    <Helmet title={seo.title}>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {!!article && <meta property="og:type" content="article" />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}

      <meta name="twitter:card" content="summary_large_image" />
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
      {seo.matchingLang && <link rel="alternate" hreflang={seo.matchingLang} href={seo.matchingUrl} />}
      {seo.matchingLang && <link rel="alternate" hreflang={lang} href={siteUrl + fixHomepage(normalizedPath)} />}
      <html lang={lang} />
      <link rel="canonical" />
    </Helmet>
  )
}
export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        title
        siteUrl
        description
        image
      }
    }
  }
`
