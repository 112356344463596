import React from 'react'
import tw from 'twin.macro'
import { StateContextProvider } from '@context/stateContext'
import SEO from './Seo'

const Main = tw.main``
const Layout = ({ children }) => {
  return (
    <>
      <SEO />
      <StateContextProvider>
        <Main key="main" tw="mx-auto max-w-screen-2k">
          {children}
        </Main>
      </StateContextProvider>
    </>
  )
}

export default Layout
